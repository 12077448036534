import React from 'react'
import image1 from '../../src/assets/images/about - 1.jpg'
import image2 from '../../src/assets/images/about - 4.jpg'
import Card from "react-animated-3d-card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const About = () => {
    const cards = [
     
        {
            
          title: "Our Functionality",
          text:
          "FaxianIT offers a range of services that cover the entire IT lifecycle, from strategy development to implementation, deployment, and maintenance. Some of the core functionalities of an IT consultancy business are as follows",
         icon: (
                <div className="icon2">
                  <i className="fa fa-thumbs-up about-icon" style={{ fontSize: 'px' }}></i>
                </div>
              )
        },
        {
          title: "Our Mission",
          text:
          "At FaxianIT “Our mission is to help businesses leverage technology to achieve their goals. We aim to be a trusted partner for our clients, delivering innovative solutions that optimize their IT infrastructure and enhance their operational efficiency.”",            
         icon: (
                <div className="icon2">
                  <i className="fa fa-bullseye about-icon" style={{ fontSize:'24px' }}></i>
                </div>
              )
        }
      ];
  return (
        <div className="container mt-5" id="About">
    <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
           
              <h6 className="who"> About FAXIAN IT</h6>
        <h1 className="ser-head" alt="Trusted IT Solutions">We Are a Trusted IT Solution Company</h1>
        <p style={{textAlign:"justify"}}>            
As a business increasingly rely on technology, IT services have become essential for ensuring the effective and efficient management of IT infrastructures. FaxianIT is a pioneer in the IT consultancy business with years off experience and empathy towards our mission to help the world progress. Below is our mission statement and structural functionality on how we proceed to help you with your goals.  </p>
<div className="cyber-about-count-box d-md-flex bg-white p-4 mt-3">
                                <div className="pe-3">
                                  <div>
   <h5 style={{color:"#263173" ,fontWeight:'1200px'}}className="h6">Need Product Demo?</h5>
   <p className="mb-0">Call Us: USA: +1(424)242-2277</p>
</div> 
                                </div>
                               
                            </div>
                        
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
      
           <div className="row mt-3">
           

           <Grid container spacing={4}>
                            {cards.map((item, index) => (
                                <Grid item xs={12} sm={12} md={12} key={index}>
                                    <Card
                                        style={{
                                            width: "100%", // Adjust width as needed
                                            cursor: "pointer",
                                            boxShadow: "none",
                                            transition: "transform 0.5s",
                                            transformStyle: "preserve-3d",
                                            backfaceVisibility: "hidden",
                                            borderRight: "10px solid rgb(255 185 63)",
                                            borderRadius: "10px"
                                        }}
                                    >
            <CardContent>
            {item.icon}
              <Typography gutterBottom variant="h5" component="div">
                
                <h4 className="title"><a href="" class="text-decoration-none title">{item.title}</a></h4>

              </Typography>
              <Typography variant="body2" color="text.secondary">
                
                <p  className="description">{item.text}</p>

              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

        
    </div>
</div>
</div>
</div>

  )
}

export default About