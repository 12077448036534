import React from "react";
import './App.css';
import FaxianITroutes from "./Routes/FaxianITroutes";
const App = () => {
  return (
   
      <FaxianITroutes/>
   
      );
};

export default App;
