import React, { useState } from 'react';
import './card.css'




const Service = () => {
 
  return (
    <div className="container mt-5" id="Services">
    <div className="text-center">
      <h6 className="who">Our Services</h6>
      <h1 alt="choose the service" className="ser-head">Choose The ServiceYou Need</h1>
      <section>
	<div class="row mt-5">
		<div class="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4">
      <main>
        <div class='card1 mt-3'>
            <div class='info1' >
            <h1  alt="Business Process Automation"class='title1'>Business Process Automation</h1>
            <p class='description1'>Business Process Automation (BPA) can revolutionize your organization by streamlining your business processes, reducing costs, improving productivity, and enhancing customer satisfaction. Here is a comprehensive pitch for Business Process Automation service that you can use to convince your boss to adopt this transformative technology</p>
            </div>
        </div>
    </main>
    </div>
		<div class="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4">     
    <main>
      <div class='card2 mt-3'>
        <div class='info2'>
          <h1 alt="Solution Architecture"class='title1'>Solution Architecture</h1>
          <p class='description1'>Solution Architecture involves designing and describing specific systems to automate processes and solve business problems. It is a structural design that addresses functional and non-functional requirements and is immediately implemented as a program, project, or change. The role of a solutions architect is to create the overall technical vision for a specific solution to a business problem and manage the solution, producing a Solution Design Document as an output.</p>
        </div>
      </div>
    </main>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4">
      <main>
      <div class='card3 mt-3'>
        <div class='info3'>
          <h1 alt="Support & Maintenance" class='title1'>Support & Maintenance</h1>
          <p class='description1'>Support maintenance refers to a set of processes and activities designed to ensure the efficient and reliable operation of a service or product. The support and maintenance activities typically involve monitoring, identifying, and resolving issues in a timely manner to minimize downtime and optimize performance.support maintenance is critical for the efficient and reliable operation of services and products. The activities involved in support maintenance are designed to proactively prevent failures, minimize downtime, optimize performance, and ensure business continuity.</p>
        </div>
      </div>
    </main>
    </div>
  </div>
  <div class="row mt-5">
  <div class="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4">	
      <main>
        <div class='card4 mt-3'>
          <div class='info4'>
            <h1 alt="Big Data Applications"class='title1'>Big Data Applications</h1>
            <p class='description1'>At FaxianIT, we are at the forefront of harnessing the power of Big Data to drive innovation and transform businesses. Our mission is to empower organizations by providing cutting-edge Big Data solutions that uncover valuable insights, enhance decision-making, and unlock new opportunities.</p>
          </div>
        </div>
      </main>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4">
      <main>
        <div class='card5 mt-3'>
          <div class='info5'>
            <h1  alt="Java"class='title1'>Java</h1>
            <p class='description1'>Java is a versatile programming language renowned for its portability, security, and reliability. At FaxianIT, we specialize in developing cutting-edge Java applications that cater to a wide range of industries and business needs. Whether it's enterprise software, web applications, mobile apps, or embedded systems, our team of expert Java developers is equipped to deliver tailored solutions that drive efficiency and innovation. </p>
          </div>
        </div>
      </main>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-4 col-xl-4">
      <main>
        <div class='card6 mt-3'>
          <div class='info6'>
            <h1  alt="IT Consulting"class='title1'>IT Consulting</h1>
            <p class='description1'>FaxianIT company provides services to align IT assets with current and anticipated business goals. This involves assessing IT governance effectiveness and the current state of the IT environment, including applications, IT infrastructure complexity, application integrations, databases and data warehouses, and development infrastructure. The company outlines a high-level IT strategy that addresses business needs and aligns IT resources with those needs. IT strategy consulting helps businesses choose the right technologies for their industry, map out an implementation strategy, and help them implement new technologies.</p>
          </div>
        </div>
      </main>
    </div>
  </div>
</section>


    </div>


    </div>
  );
};

export default Service;
