import React from 'react'
import image from '../../src/assets/images/banner- bg.png'
// import video1 from '../../src/assets/images/signinsoft.mp4'


const Section = () => {
  return (
    <div>
        <section id="home" className="d-flex align-items-center">
  <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-5 text-center">
          <h1 alt="Welcome to FaxianIT" className='mt-5'>Welcome To FaxianIT</h1>
    <h2 className="banner-text">We Provide Best Services and Support for all Your Needs.</h2>
    <div className="text-center">
      <a href="#About" className="btn-get-started scrollto">MORE INFO</a>
      
    </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 scale-up-hor-center mt-2 img-fluid"/></div>
  </div>
  </div>
</section>

 
    </div>

  )
}

export default Section