import React from 'react'
import { Route,Routes } from "react-router-dom";
import Home from '../Home/home';
import Pagenotfound from '../pagenotfound';


const FaxianITroutes = () => {
  return (
    <div>
        
        <Routes>
            <Route path="/" element= {<Home/>}/>
            <Route path="/Home" element= {<Home/>}/>
            <Route path="/*" element= {<Pagenotfound/>}/>
          
        </Routes>
        
    </div>
  )
}

export default FaxianITroutes
