import React from 'react'
import img1 from '../assets/images/SalesForce.jpg'
import img2 from '../assets/images/Service.png'
import img3 from '../assets/images/AWS.png'
import img4 from '../assets/images/Azure.png'
import './cloudtechnologies.css'

const Cloudtechnologies = () => {
  return (
    <div>
        <section id="CloudTechnologies" className="contact  mt-5">
        <div className="container" data-aos="fade-up">

          <div className="text-center">
            <h6 className="who mt-3">Cloud Technologies</h6>
            <h3 className="ser-head">Latest Technologies you can Adopt</h3>
          </div>
          <div class="row mt-5">
          <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6">
                   <div class="card p-3 mt-3">
                    <div className='img-ct text-center mt-3'>
                            <img src={img1} alt="Salesforce" style={{width:'100%', height:'150px'}}></img>
                            </div>
                            <h1 alt=" Salesforce" style={{color:'#263173', textAlign:'center'}}>Salesforce</h1>
                            <p className='para'>FaxianIT offers the best and innovative Salesforce solutions that match your business needs. Our multidisciplinary consultants are ready to help you with multiple challenges of your Salesforce ecosystem. This includes expert guidance and support in purchasing, implementing, and optimizing the right Salesforce business applications for your business. 

By leveraging on our Salesforce solutions you can re-imagine and reinvent your relationships with your customers. Our Experts help you with implementation, development and support.</p>
                           
                            </div>
                 </div>
                 <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6">
                   <div class="card p-3 mt-3">
                   <div className='img-ct text-center mt-3'>
                            <img src={img2} alt="Service" style={{width:'220px', height:'170px', }}></img>
                            </div>
                            <h1 alt="Servicesnow" style={{color:'#263173',textAlign:'center'}}>Servicesnow</h1>
                            <p className='para'>FaxianIT helps you to enhance your employee’s productivity and customer satisfaction with ServiceNow. Understandably, Service Now Platform accelerates work, simplifies customer service, and fortifies business operations. All this means to create a clear digital transformation path. Through Service Now cloud platform’s we achieve for your business process automation, enhanced digital experience, and lean operations. Our experts offer end-to-end Service Now Implementation services  </p>
                           
                            </div>
                 </div>
                 </div>
          <div class="row mt-5">
          <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6">
                   <div class="card p-3 mt-3">
                   <div className='img-ct text-center mt-3'>
                            <img src={img3} alt="AWS" style={{width:'200px', height:'150px'}}></img>
                            </div>
                            <h1  alt="AWS" style={{color:'#263173',textAlign:'center'}}>AWS</h1>
                            <p className='para'>FaxianIT team of Amazon Web Services (AWS) experts accelerates your cloud adoption with AWS. Our focus is definitely on extending proven technical expertise and engineering legacy to drive innovation, scale, and achieve enhanced business agility.

As your dedicated AWS Consulting Partner, we bring to you innovative and value-driven AWS competencies in Digital & Cloud Transformation, DevOps, and Migration. </p>
                            </div>
                 </div>
                 <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6">
                   <div class="card p-3 mt-3">
                   <div className='img-ct text-center mt-3'>
                            <img src={img4} alt="Azure" style={{width:'200px', height:'150px'}}></img>
                            </div>
                            <h1 alt="Azure" style={{color:'#263173',textAlign:'center'}}>Azure</h1>
                            <p className='para'>Innovative and end-to-end Azure Cloud Managed Services. Our team of experienced and certified Azure experts render anytime, and anywhere Azure cloud consulting and support. Significantly, our primary objective is to ensure a frictionless digital transformation on Azure.

Significantly, the focus of FaxianIT is on achieving successful cloud journeys for businesses. Our Azure consulting practice verifies your cloud readiness and assessment processes, pinpoint current and future business needs.</p>
                           
                            </div>
                 </div>
            </div>
            </div>
            </section>
    
    </div>
  )
}

export default Cloudtechnologies
