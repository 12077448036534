import React from 'react'
import image from '../src/assets/images/Logo.png'

const Footer = () => {
  return (
   <div>
    <footer class="footer-section mt-5">
        <div class="container">
           
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="/#home"><img src={image} class="img-fluid" alt="logo"/></a>
                            </div>
                            <div class="footer-text">
                            <p>As businesses increasingly rely on technology, IT services have become essential for ensuring the effective and efficient management of IT infrastructures. FaxianIT is a pioneer in the IT consultancy business with years off experience and empathy towards our mission to help the world progress.</p>                            </div>
                            
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Useful Links</h3>

                           
                            </div>
                            <ul>
                                <li><a href="/#home">Home</a></li>
                                <li><a href="/#About">About us</a></li>
                                <li><a href="/#Services">Services</a></li>
                                <li><a href="/#CloudTechnologies">Cloud Technologies</a></li>
                                <li><a href="/Products">Career</a></li>
                                <li><a href="/#contact">Contact</a></li>
                               
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Follow us</h3>
                            </div>
                            <div class="footer-social-icon">
                              
                              <a href="https://faxianit.azurewebsites.net/" target="_blank"><i class="fa fa-facebook-f"></i></a>
                              <a href="https://faxianit.azurewebsites.net/" target="_blank"><i class="fa fa-linkedin-square"></i></a>
                          </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="copyright-area">
            <div class="container">
                
                    <div class="text-center">
                        <div class="copyright-text">
                         <p>© Copyright 2022 - FaxianIT Inc. All Rights Reserved <br/> Designed by <a href="https://www.knackhook.com/" target="_blank" style={{color:"orange"}}> KnackHook </a></p>
                        </div>
                    </div>
                    
                
            </div>
        </div>
    </footer>

   </div>
   
  )
}

export default Footer