import React from 'react'
import image1 from '../assets/images/careernbg.png'
import { Link  } from 'react-router-dom'


const Career = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior for a nice effect
    });
  };
  
  return (
    <div>
        <div className="container mt-5" id="Career">
            <div className="text-center">
        <h6 className="who">Career</h6>
        <h1 className="ser-head" alt='Career Benifits with us'>Explore the Benefits with Us</h1>
      </div>
    <div className="row ">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 scale-up-center">
            <div className="cyber-about-img text-center mb-30 mb-lg-0">
                <img src={image1} alt="Career" className="img-fluid"/>
                
            </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
            <h6 className="who">About Career</h6>
            <p style={{textAlign:"justify"}}>Picture yourself at one of the world's best places to work, surrounded by teams and people who challenge you, support you, and inspire you to be extraordinary.</p>
            <Link to="/Careerjobs " target="_top">
            <div class="d-flex1 justify-content-center1 align-items-center1  ">
            <div className="d-flex">
      <a href = "/#contact" className="btn btn-primary" >Contact Us</a>
      
    </div>
</div>
</Link>

</div>

</div>
</div>

</div>

  )
}

export default Career