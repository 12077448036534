
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../src/assets/images/Logo.png';



const Header = () => {



  return (
    <div>
     <div className="header">
    
     <nav  className="navbar navbar-expand-lg navbar-light ">
            <div className="container">
              <a className="navbar-brand" >
  <Link to="/Home">
    <img src={logo} alt="Intelli Vision Logo" className="FaxianIT-logo" />
    </Link></a>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
  <i class="fa fa-bars" aria-hidden="true" style={{color:'#fff'}}></i>
  </button>
  <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div className="navbar-nav " style={{marginLeft:"auto"}}>
    
      
        <a className="nav-link scrollto active navhome" aria-current="page" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}}  href="/#home">Home</a>
        <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}}  href="/#About">About us</a>
        <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}}  href="/#Services">Services</a>
      
       
        <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}} href="/#CloudTechnologies" >Cloud Technologies</a>
        <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}} href="/#Career" >Career</a>
        <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}}  href="/#contact">Contact</a>
       
      </div>
      
  </div>

    </div>
    
   
</nav>
</div>
    </div>

  )
}

export default Header