
import React,{useEffect} from 'react';
import Header from '../header';
import Section from './section';
import About from './about';
import Service from './service';
import Contact from './contact';
import Footer from '../footer';
import Topnav from '../Topnav';
import Cloudtechnologies from './cloudtechnologies';
import Career from './career';


const Home = () => {

  useEffect(() => {
    const scrollToSection = () => {
      const hash = window.location.hash;
  
      if (hash) {
        const targetSection = document.querySelector(hash);
  
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
  
    scrollToSection();
  
    window.addEventListener('load', scrollToSection);
  
    return () => {
      window.removeEventListener('load', scrollToSection);
    };
  }, []);
  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (

    <div>
        <Topnav/>
        <Header/>
        <Section/>
        <div id="About">
          <About/>  
        </div>
        <div id="Services">
           <Service/>
         </div>
       <div id="CloudTechnologies">
           <Cloudtechnologies/>
       </div> 
       <div id="Career">
           <Career/>
       </div> 
        <div id="contact">
           <Contact/>
         </div>
         <div><a href="/#navbarNavAltMarkup"><button className='myBtn' onClick={scrollToTop}><i class="fa fa-arrow-up" aria-hidden="true"></i></button></a></div>
  <Footer/>
  
</div>
    
     
     
      
      
     
     
    
      
   
  );
};

export default Home;





