import React from 'react'

const Contact = () => {
  return (
    <div>
      <section id="contact" className="contact mt-2">
        <div className="container" data-aos="fade-up">

          <div className="text-center">
            <h6 className="who mt-3">Contact</h6>
            <h3 className="ser-head">Get in Touch</h3>
            </div>

         
          <div className="row mt-5" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <div className="icon-container mt-3">
                  <i className="fa fa-map-marker map-icon-3d fa-5x fa-3dicon "></i>
                </div>
                <h3>Our Address</h3>
                <p>FaxianIT Inc. 4320 Winfield Road, Suite 200 Cornerstone @ Cantera Warrenville Illinois 60555 United States of America</p>
              <hr/>
              <p >FaxianIT Inc. 371 Hoes Lane, Suite 200/E-4 Piscataway, NJ 08854 USA</p>
              
               </div>
            </div>
            
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <i className="fa fa-envelope  fa-animation-envelope mb-2 mt-3" ></i>
                <br/>
                <h3 >Email Us</h3>
                <p>info@faxianit.com</p>
                
              </div>
            </div>
           
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <div className="pulse" style={{marginBottom:28 ,marginTop:20}}>
                  <i className="fa fa-phone " ></i>
                </div>
                
                <h3>Call Us</h3>
                <p>+1(424)242-2277</p>
                </div>
            </div></div>
            
        

        </div>
      </section>

    </div>
  )
}



export default Contact